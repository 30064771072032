import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { HeaderComponent } from "../header/header.component";
import { JobsService } from "src/app/services/jobs.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LoginService } from "src/app/services/login.service";
import { UserServiceService } from "src/app/services/user-service.service";;
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.css']
})
export class AllCategoriesComponent implements OnInit {
  loading: boolean = false;
  AllCategoriesForm: any = FormGroup;
  searchTermForm: any = FormGroup;
  baseUrl: string = "";
  category: any = [];
  categoryRes: any = {};
  user: any = "";
  categoriesList: Array<any> = [];
  All_categoriesList: Array<any> = [];
  filteredCategories: any[] = [];
  showError: boolean = false;
  selectedLanguage: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<HeaderComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jobsService: JobsService,
    private loginService: LoginService,
    private http: HttpClient,
    private userService: UserServiceService,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    this.getCategories();
    this.baseUrl = this.loginService.baseUrl;
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log(this.user);

    this.searchTermForm = this.fb.group({
      keyword: ['']
    });
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  getCategories() {
    console.log(this.loginService.categoriesList)
    this.loginService.showLoader.next(true);
    if (
      this.loginService.categoriesList && this.loginService.categoriesList.length > 0
    ) {
      this.All_categoriesList = this.loginService.categoriesList.categoriesList;
      // this.category = this.loginService.categoriesList;
      this.category = this.loginService.categoriesList.categoriesList;
      console.log(this.All_categoriesList);
      // this.categoryRes = this.loginService.categoriesList.isUpdate[0];
      this.loginService.showLoader.next(false);
      return;
    }
    this.jobsService.browseCategory().subscribe(
      (posRes) => {
        if (posRes.response == 3) {
          this.category = posRes;
          this.loginService.showLoader.next(false);
          this.loginService.categoriesList = posRes;
          this.All_categoriesList = this.loginService.categoriesList.categoriesList;
          console.log(this.All_categoriesList);
        } else {
          this.loginService.showLoader.next(false);
        }
      },
      (err: HttpErrorResponse) => {
        this.loginService.showLoader.next(false);
        // this.dialogRef.close();       
        const txtmsg1 = this.translateService.instant("Server Failure try after some time..");
        this.openSnackBar(txtmsg1, '');
        if (err.error instanceof Error) {
          console.warn("CSE", err.message);
        } else {
          console.warn("SSE", err.message);
        }
      }
    );
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }
  closeTab() {
    this.dialogRef.close();
  }

  submitDetails() {

  }



  showService(item: any) {
    this.dialogRef.close();
    const objString = JSON.stringify(item);
    localStorage.setItem("myObject", objString);
    this.loginService.callPostJob.next(true);
  }


  searchCategory() {
    const keyword = this.searchTermForm.get('keyword').value.toLowerCase();  
    if (keyword === '') {
      this.All_categoriesList = this.loginService.categoriesList.categoriesList;
      this.showError = false;
    } else {
      this.filteredCategories = this.All_categoriesList.filter(category =>
        category.categoryName.toLowerCase().includes(keyword)
      );
  
      if (this.filteredCategories.length === 0) {
        this.showError = true;
      } else {
        this.showError = false;
        this.All_categoriesList = this.filteredCategories;
      }
    }
  }

}
