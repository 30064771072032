import { Component, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { LoginService } from "src/app/services/login.service";
import { ActivatedRoute, Router } from "@angular/router";
import { sha256, sha224 } from "js-sha256";
import { JobsService } from "src/app/services/jobs.service";
import { HttpErrorResponse } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { V } from "@angular/cdk/keycodes";
import { PrefferedListComponent } from "../preffered-list/preffered-list.component";
import { MatDialog, MatSnackBar } from "@angular/material";
import { AllCategoriesComponent } from '../all-categories/all-categories.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit { 
  user: any;
  isLoggedIn: boolean = false;
  searchTermForm: FormGroup;
  refferObj: any;
  shareUrl: any = "https://www.Talentasker.com/#/home";
  shareTaskTitle: any = "TalenTasker";
  isSocialBtnsHidden: boolean = false;
  jobsCount: any;
  jobsCount1: Array<any> = [];
  cntArry = [];
  valArry = [];
  currentDate: any = new Date();
  selectedCat: Array<any> = [];
  categoriesList: Array<any> = [];
  categories10List: Array<any> = [];
  baseUrl: string = "";
  showRemaining: boolean = false;
  showPopup: boolean = false;
  remainingItems: any[] = [];
  categoryRes: any = {};
  category: any = [];
  SubCategory: any = [];
  selectedLanguage: string;
  constructor(
    private config: NgbCarouselConfig,
    private loginService: LoginService,
    private router: Router,
    private jobService: JobsService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.loginService.checkIsLoggedIn.subscribe((val) => {
      if (!val) {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
    });
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    this.baseUrl = "http://india-live-api.talentasker.com";
    this.getCategories();
    this.fetchJobsDoneCount();
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.refferObj = { ...params };
    });

    this.searchTermForm = this.fb.group({
      keyword: ["", Validators.required],
    });
    this.config.showNavigationArrows = true;
    this.config.showNavigationIndicators = true;
    this.config.pauseOnHover = false;   
    this.user = JSON.parse(localStorage.getItem("user"));
    this.isLoggedIn = this.user && this.user.userID ? true : false;
    if (this.refferObj.params && this.refferObj.params.postId) {
      this.router.navigate([
        "/browsejobs",
        "job",
        this.refferObj.params.postId,
      ]);
    }
   if (this.isLoggedIn) {
      console.log("useeer",this.user);
      console.log("useID",this.user.referralCode);
      if (this.user && this.user.referralCode) {
        this.getShortLink();
      }
      if (this.user && this.user.completeTask && !this.user.category.length) {
            this.openCategories();
          }
    }
    // if (this.isLoggedIn) {
    //   this.getShortLink();
    //   if (this.user && this.user.completeTask && !this.user.category.length) {
    //     this.openCategories();
    //   }
    // }
    if (
      !this.isLoggedIn &&
      this.refferObj.params &&
      this.refferObj.params.invitedby
    ) {
      localStorage.setItem("refferal", JSON.stringify(this.refferObj.params));
      let url: string = this.router.url.substring(
        0,
        this.router.url.indexOf("?")
      );
      this.router.navigateByUrl(url);
      this.loginService.callSignUp.next(true);
    }
    this.switchLanguage(this.selectedLanguage);
  }
  

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }

  toggleRemainingItems() {
    this.GotoAllCategories();
  }

  getCategories() {
    console.log(this.loginService.categoriesList)
    this.loginService.showLoader.next(true);
    if (
      this.loginService.categoriesList && this.loginService.categoriesList.length > 0
    ) {
      this.categories10List = this.loginService.categoriesList.categoriesList;
      // this.category = this.loginService.categoriesList;
      this.category = this.loginService.categoriesList.categoriesList;    
      console.log(this.categories10List);
      // this.categoryRes = this.loginService.categoriesList.isUpdate[0];
      this.loginService.showLoader.next(false);
      return;
    }
    this.jobService.browseCategory().subscribe(
      (posRes) => {
        if (posRes.response == 3) {
          this.category = posRes;
          this.loginService.showLoader.next(false);
          this.loginService.categoriesList = posRes;
          this.categories10List = this.loginService.categoriesList.categoriesList;
          this.category = this.loginService.categoriesList.categoriesList;
          //this.SubCategory = this.loginService.categoriesList.categoriesList.subCategorys;
          console.log(this.categories10List);
          console.log(  this.SubCategory);
        } else {
          this.loginService.showLoader.next(false);
        }
      },
      (err: HttpErrorResponse) => {
        this.loginService.showLoader.next(false);
        const txtmsg1 = this.translateService.instant("Server Failure try after some time..");
        this.openSnackBar(txtmsg1, '');
        if (err.error instanceof Error) {
          console.warn("CSE", err.message);
        } else {
          console.warn("SSE", err.message);
        }
      }
    );
  }

  searchJobs() {
    if (this.isLoggedIn) {
      this.router.navigate(["/browsejobs"], {
        queryParams: { search_term: this.searchTermForm.value.keyword },
      });
    } else {
      this.loginService.callLogin.next(true);
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }
  // Select Preffered Categories
  openCategories() {
    let token = localStorage.getItem("token");
    let dailogRef = this.dialog.open(PrefferedListComponent, {
      panelClass: "col-md-3",
      hasBackdrop: true,
      disableClose: true,
      data: this.selectedCat,
    });
    dailogRef.afterClosed().subscribe((res) => {
      if (res.length) {
        this.selectedCat = [...res];
        let obj = {
          userID: this.user.userID,
          category: this.selectedCat,
        };
        this.loginService.addCategories(obj,token).subscribe(
          (posRes) => {
            if (posRes.response == 3) {
              const txtmsg = posRes.message.trim();
              const txtmsg1 = this.translateService.instant(txtmsg);
              this.openSnackBar(txtmsg1, '');
            }
          },
          (err: HttpErrorResponse) => {
            console.warn(err.error);
          }
        );
      } else {
        const txtmsg = this.translateService.instant("Please select the preferred tasks, you choosed as a worker");
        this.openSnackBar(txtmsg, '');
      }
    });
  }
  showBtns() {
    this.isSocialBtnsHidden = true;
  }
  close() {
    this.isSocialBtnsHidden = false;
  }
  //  SHare refferal code in social media
  getShortLink(): any {
    this.loginService.showLoader.next(true);
    let obj = {
      // dynamicLinkInfo: {        
      //   domainUriPrefix: "https://talentaskerin.page.link/",
      //   link: `https://talentasker.com/#/home/?invitedby=${this.user.firstName}&referralCode=${this.user.referralCode}`,
      //   androidInfo: {
      //     androidPackageName: "com.Softexer.TalenTasker",
      //   },
      //   iosInfo: {
      //     iosBundleId: "com.SoftexerPrivateLimited.TalenTasker",
      //   },
      //   socialMetaTagInfo: {
      //     socialTitle: this.user.firstName,
      //     socialDescription: `Have you tried TalenTasker? Sign up with my link ad you will get up to ₹ 25 off your task.`,
      //     socialImageLink:
      //       "https://www.Talentasker.com/assets/Group20.png",
      //   },
      // },
      "dynamicLinkInfo": {
        "domainUriPrefix": "https://talentaskerin.page.link",
        "link": `https://www.talentasker.com/#/home/?invitedby=${this.user.firstName}&referralCode=${this.user.referralCode}`,
        "androidInfo": {
          "androidPackageName": "com.vedaslabs.softexer"
        },
        "iosInfo": {
          "iosBundleId": "com.SoftexerPrivateLimited.Softexer"
        },
        "socialMetaTagInfo": {
          "socialTitle": this.user.firstName,
          "socialDescription": `Have you tried TalenTasker? Sign up with my link ad you will get up to ₹ 25 off your task..`,
          "socialImageLink": "https://www.Talentasker.com/assets/Group20.png"
        }
      },
      "suffix": {
        "option": "UNGUESSABLE"
      }
    };
    console.log(obj)
    this.loginService.getShortLink(obj).subscribe(
      (posRes) => {
        console.log("Reffeal Link", posRes.shortLink);
        this.loginService.showLoader.next(false);
        this.shareUrl = posRes.shortLink;
      },
      (err: HttpErrorResponse) => {
        this.loginService.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      }
    );
  }
  fetchJobsDoneCount() {
    this.loginService.showLoader.next(true);
    let token = localStorage.getItem("token");
    this.loginService.getCompletedJobsCount(token).subscribe(
      (posRes) => {
        if (posRes.response == 3) {
          this.jobsCount = posRes.count;
          this.jobsCount1 = [posRes.count];
          var ddd = Object.keys(this.jobsCount1[0]);
          this.cntArry = ddd;
          let xx = Object.values(this.jobsCount1[0]);
          this.valArry = xx;
        }
      },
      (err: HttpErrorResponse) => {
        console.log("err", err.message);
      }
    );
    this.loginService.showLoader.next(false);
  }
  shareOnFaceBook() {
    this.isSocialBtnsHidden = false;
    let faceBookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
      this.shareUrl
    )}?t=${encodeURIComponent(this.shareTaskTitle)}`;
    window.open(faceBookUrl, "_blank");
  }
  shareOnWhatsApp() {
    this.isSocialBtnsHidden = false;
    let url =
      "https://api.whatsapp.com/send?" +
      "&text=" +
      encodeURIComponent(this.shareUrl);
    window.open(url, "_blank");
  }
  shareOnTwiter() {
    this.isSocialBtnsHidden = false;
    let twitterUrl = `https://twitter.com/share?url=${encodeURIComponent(
      this.shareUrl
    )}&amp;text=${encodeURIComponent(
      this.shareTaskTitle
    )}&amp;via=fabienb&amp;hashtags=koandesign`;
    window.open(twitterUrl, "_blank");
  }
  shareOnLinkedIn() {
    this.isSocialBtnsHidden = false;
    let linkedInUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
      this.shareUrl
    )}&amp;title=${encodeURIComponent(this.shareTaskTitle)}`;
    window.open(linkedInUrl, "_blank");
  }
  shareOnPinterest() {
    this.isSocialBtnsHidden = false;

    let pinterestLink = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      this.shareUrl
    )}share&description=${encodeURIComponent(this.shareTaskTitle)}`;
    window.open(pinterestLink, "_blank");
  }

  showService(item: any) {
    if (this.isLoggedIn == true) {
      const objString = JSON.stringify(item);
      localStorage.setItem("myObject", objString);
      console.log("selectedcat", objString);
      this.loginService.callPostJob.next(true);
    }
    else {
      this.loginService.callLogin.next(true);
    }
  }

  openPostJob() {
    this.loginService.callPostJob.next(true);
  }
  openBrowseJobs() {
    this.router.navigateByUrl("/browsejobs");
  }

  GotoAllCategories() {
    let dailogRef = this.dialog.open(AllCategoriesComponent, {
      panelClass: "col-md-4",
      hasBackdrop: true,
      disableClose: true,
    });
    dailogRef.afterClosed().subscribe((val) => {
      if (val) {
        // this.categories.push(val);
      }
    });
  }

}
