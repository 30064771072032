import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { RewardsDailogComponent } from '../rewards-dailog/rewards-dailog.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {
showRewards:boolean = true;
selectedLanguage: string;
  constructor(private dialog:MatDialog,
    private translateService: TranslateService,
    private languageService: LanguageService) {
      this.languageService.selectedLanguage$.subscribe(language => {
        this.selectedLanguage = language;      
      });
     }

  ngOnInit() {
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  openReward(){
let dialogRef = this.dialog.open(RewardsDailogComponent,{
  panelClass: 'col-md-4',
  hasBackdrop: true,
  disableClose:true
})
  }
}
