import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { FormGroup } from "@angular/forms";
import { MatDialog, MatSnackBar } from "@angular/material";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { UserServiceService } from "src/app/services/user-service.service";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { WithdrawComponent } from "../withdraw/withdraw.component";
import { GiveRatingsComponent } from "../give-ratings/give-ratings.component";
import { CompleteTaskDialogComponent } from "../complete-task-dialog/complete-task-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { LoginService } from "src/app/services/login.service";
import { AttachmentSwiperComponent } from "../attachment-swiper/attachment-swiper.component";
import { sha256, sha224 } from "js-sha256";
import { JobsService } from "src/app/services/jobs.service";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

export interface Withdraw {
  bookingID: string;
  offeredUserID: string;
  isWithDraw: boolean;
  withDrawnByPoster: boolean;
  withDrawReason: string;
}

@Component({
  selector: "app-booking-details",
  templateUrl: "./booking-details.component.html",
  styleUrls: ["./booking-details.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class BookingDetailsComponent implements OnInit {
  selectedLanguage: string;
  totalSubscribe: Subscription;
  id: string;
  rawData: any;
  offers: any;
  commentForm: FormGroup;
  comments: any = [];
  date: any;
  routeSub: any;
  user: any;
  isPendingBooking: boolean = false;
  taskUserInfo: any;
  iamPoster: boolean = false;
  image: string;
  one: boolean = false;
  two: boolean = false;
  three: boolean = false;
  four: boolean = false;
  five: boolean = false;
  bookedTaskers: any;
  faceBookUrl: any;
  linkedInUrl: any;
  twitterUrl: any = "";
  shareUrl: any = "";
  shareTaskTitle: any = "";
  showCheckBoxes: boolean = false;
  selectedTaskers: Array<any> = [];
  isBookingWithDrawnByAllTaskers: boolean = false;
  userImage: string = "https://india-live-api.talentasker.com/user.png";
  showOptions: boolean = false;
  loggedIn: boolean = false;
  isTaskerWithdrawn: boolean = false;
  isTaskerCompleted: boolean = false;
  isTaskerGivenRating: boolean = false;
  isCustomerCompled: boolean = false;
  isPosterCompletedTask: boolean = false;
  public OrderNumber: any;
  public PaymentID: any;
  public orderDesc = "Purchasing";
  public merchantReturnURL = "https://www.Talentasker.com/#/my-tasks";
  public merchantCallBackURL = "";
  public custName = "John Doe";
  public custEmail = "Jason@gmail.com";
  public custPhone = "60126902328";
  public PageTimeout = 500;
  public custIP: any;
  public currencyCode = "MYR";
  public custOCP = "OCP";
  public PaymentDesc = "testing";
  public TransactionType = "SALE";
  public PymtMethod = "ANY";
  public merchantName = "TalenTasker";
  public merchantTermsURL = "https://www.Talentasker.com/terms/";
  public pass = "H71BRDU8";
  paymentObj: any;
  descriptionlang: string = "English";
  showRemaining: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserServiceService,
    private loginService: LoginService,
    private jobService: JobsService,
    private router: Router,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.router.events.subscribe((val) => { });
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }
  ngOnInit() {
    this.onLoad();
    this.switchLanguage(this.selectedLanguage);
  }
  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }

  showUserProfile(id) {
    this.router.navigate(["/profile", btoa(id)], {
      queryParams: { isEncpt: "y" },
    });
  }
  onLoad() {
    this.showOptions = false;
    this.loggedIn = false;
    this.isTaskerWithdrawn = false;
    this.isTaskerCompleted = false;
    this.isTaskerGivenRating = false;
    this.isCustomerCompled = false;
    this.isPosterCompletedTask = false;
    this.getIP();
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.getTaskDetails();
    });
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user != null) {
      this.loggedIn = true;
      if (this.user.profilePic !== "") {
        this.userImage =
          "https://india-live-api.talentasker.com" + this.user.profilePic;
      }
    } else {
      this.loggedIn = false;
    }
    this.image = "https://india-live-api.talentasker.com/user.png";
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }

  getIP() {
    this.loginService.getIPAddress().subscribe((res: any) => {
      this.custIP = res.ip;
    });
  }
  getTaskDetails() {
    this.two = false;
    this.three = false;
    this.four = false;
    this.five = false;
    this.isBookingWithDrawnByAllTaskers = false;
    this.loginService.showLoader.next(true);
    let obj = {
      bookingID: this.id,
    };
    this.selectedTaskers = [];
    let token = localStorage.getItem("token");
    this.totalSubscribe = this.userService.bookingDetails(obj, token).subscribe(
      (posRes) => {
        this.loginService.showLoader.next(false);
        if (posRes.response == 3) {
          this.rawData = posRes.bookingData;
          this.date = new Date(posRes.bookingData.taskDate[0] * 1);          
          if (this.rawData && this.rawData.userID) {
            let num = this.rawData.userID
              .replace(/[^0-9 ]/g, "")
              .replace(/ /g, "");
            if (num.substring(0, 2) != "60") {
              num = "+91" + num;
            }
            this.rawData.phoneNumber = num;
          }

          if (this.rawData.paymentStatus == "Pending") {
            this.isPendingBooking = true;
          } else {
            this.isPendingBooking = false;
          }
          this.shareUrl =
            "http://www.Talentasker.com/#/browsejobs/job/" + this.rawData.postID;
          this.shareTaskTitle = this.rawData.postTitle;
          if (this.rawData.customerProfilePic != "") {
            this.image =
              "https://india-live-api.talentasker.com" +
              this.rawData.customerProfilePic;
          }
          if (this.rawData.userID == this.user.userID) {
            this.iamPoster = true;
            this.bookedTaskers = this.rawData.bookedTaskers;           
            this.bookedTaskers = this.bookedTaskers.map((tasker) => {
              if (tasker && tasker.offeredUserID) {
                tasker.phoneNumber = tasker.offeredUserID
                  .replace(/[^0-9 ]/g, "")
                  .replace(/ /g, "");
              }
              return tasker;
            });
            // Removing withdrawn providers
            let activeProviders = this.bookedTaskers.filter((val) => {
              return !val.isWithDraw && !val.isWithDrawCustomer;
            });
            if (activeProviders.length) {
              let taskCompletedProviders = activeProviders.filter((val) => {
                return val.isCustomerCompletedTask === true;
              });
              if (taskCompletedProviders.length == activeProviders.length) {
                this.isPosterCompletedTask = true;
                this.one = true;
                this.two = true;
                this.three = true;
                this.four = true;
                let paymentCompletedProviders = activeProviders.filter(
                  (val) => {
                    return val.paymentStatusToProviderByAdmin;
                  }
                );
                if (
                  paymentCompletedProviders.length == activeProviders.length
                ) {
                  this.five = true;
                } else {
                  this.five = false;
                }
              } else {
                this.two = false;
                this.three = false;
                this.four = false;
                let selfCompletedProviders = activeProviders.filter((val) => {
                  return val.isTaskerCompletedTask;
                });
                if (selfCompletedProviders.length == activeProviders.length) {
                  this.one = true;
                  this.two = true;
                } else {
                  this.two = false;
                }
              }
            }
            if (activeProviders.length == 0) {
              this.isBookingWithDrawnByAllTaskers = true;
            } else {
              this.isBookingWithDrawnByAllTaskers = false;
            }

            // End here
          } else {
            this.userService.isTasker.next(true);
            this.iamPoster = false;
            if (this.rawData && this.rawData.userID) {
              this.rawData.phoneNumber = this.rawData.userID
                .replace(/[^0-9 ]/g, "")
                .replace(/ /g, "");
            }
            let index = -1;
            index = this.rawData.bookedTaskers.findIndex((val) => {
              return val.offeredUserID == this.user.userID;
            });
            if (index != -1) {
              this.bookedTaskers = this.rawData.bookedTaskers[index];
              if (this.bookedTaskers.paymentStatusToProviderByAdmin) {
                this.isTaskerWithdrawn = false;
                this.one = true;
                this.two = true;
                this.three = true;
                this.four = true;
                this.five = true;
                this.isTaskerCompleted = true;
                if (this.bookedTaskers.ratingsToPoster) {
                  this.isTaskerGivenRating = true;
                } else {
                  this.isTaskerGivenRating = false;
                }
              } else {
                this.isTaskerWithdrawn = false;
                this.one = false;
                this.two = false;
                this.three = false;
                this.four = false;
                this.five = false;
                this.isTaskerCompleted = false;
                if (this.bookedTaskers.isWithDraw) {
                  this.isTaskerWithdrawn = true;
                } else {
                  this.isTaskerWithdrawn = false;
                  if (this.bookedTaskers.isCustomerCompletedTask) {
                    this.one = true;
                    this.two = true;
                    this.three = true;
                    this.four = true;
                    this.isTaskerCompleted = true;
                    this.isPosterCompletedTask = true;
                    if (this.bookedTaskers.ratingsToPoster) {
                      this.isTaskerGivenRating = true;
                    } else {
                      this.isTaskerGivenRating = false;
                    }
                  } else if (this.bookedTaskers.isTaskerCompletedTask) {
                    this.one = true;
                    this.two = true;
                    this.isTaskerCompleted = true;
                    this.isPosterCompletedTask = false;
                    if (this.bookedTaskers.ratingsToPoster) {
                      this.isTaskerGivenRating = true;
                    } else {
                      this.isTaskerGivenRating = false;
                    }
                  } else {
                    this.isTaskerWithdrawn = false;
                    this.one = true;
                    this.two = false;
                    this.three = false;
                    this.four = false;
                    this.isTaskerCompleted = false;
                    this.isPosterCompletedTask = false;
                  }
                }
              }
            }
          }
        } else {
          this.loginService.showLoader.next(false);
          const txtmsg = posRes.message;
          const txtmsg1 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg1, '');;
        }
      },
      (err) => {
        this.loginService.showLoader.next(false);
        const txtmsg1 = this.translateService.instant("Server Failure try after some time..");
        this.openSnackBar(txtmsg1, '');
      }
    );
  }
  getWhatsappUrl(tasker) {
    let number = tasker.offeredUserID.replace(/[^0-9 ]/g, "").replace(/ /g, "");
    if (number.substring(0, 2) !== "60") {
      number = "+91" + tasker.offeredUserID;
    }
    return `https://wasap.my/${number}`;
  }
  getCallUrl(tasker) {
    let number = tasker.offeredUserID.replace(/[^0-9 ]/g, "").replace(/ /g, "");
    if (number.substring(0, 2) !== "60") {
      number = "+91" + tasker.offeredUserID;
    }
    return
  }
  retryPayMent() {
    let RetbookingID = "STR" + new Date().getTime()
    let updateObj = {
      bookingID: this.rawData.bookingID,
      postID: this.rawData.postID,
      newbookingID: RetbookingID
    }
    let token1 = localStorage.getItem("token");
    this.jobService.UpdateBooking(updateObj, token1).subscribe(
      (posRes: any) => {
        if (posRes.response == 3) {
          this.PaymentID = RetbookingID;
          let money = Number(this.rawData.taskTotalBudget);
          let obj = {
            merchantTransactionId: this.PaymentID,
            // Amount: 1,
            Amount: Number(money),
            mobileNumber: this.user.userID,
            userID: this.user.userID + "@talentasker.com",
            type: "WEB",
            callbackURL: "https://talentasker.com/#/my-tasks/task/" + this.rawData.postID
            // paymentID: this.PaymentID,
            // amount: money,
          };
          localStorage.setItem("paymentStatus", JSON.stringify(obj));
          let token = localStorage.getItem("token");
          this.jobService.getPhonePeInit(obj, token).subscribe(
            (posRes: any) => {
              const equalsIndex = posRes.data.instrumentResponse.redirectInfo.url.indexOf('=');
              if (equalsIndex !== -1) {
                const substringAfterEquals = posRes.data.instrumentResponse.redirectInfo.url.substring(equalsIndex + 1);
                const form = `<form name="frmPayment" method="GET" action="https://mercury-t2.phonepe.com/transact/pg">
       <input type="hidden" name="token" id="tokenField" value=${substringAfterEquals}>
       </form>`;

                const paymentElement = document.querySelector(".payment") as HTMLFormElement | null;
                if (paymentElement) {
                  paymentElement.innerHTML = form;
                  const frmPayment = document.querySelector("form[name='frmPayment']") as HTMLFormElement | null;
                  if (frmPayment) {
                    frmPayment.submit();
                  } else {
                    console.error("Form with name 'frmPayment' not found.");
                  }
                } else {
                  console.error("Element with class 'payment' not found.");
                }
              } else {
                this.openSnackBar("Technical issue will try some time", "")
              }
            })
        } else {
          this.openSnackBar(posRes.message, "");
        }
      })
  }

  deleteBooking() {
    let delObj = {
      bookingID: this.rawData.bookingID,
    };
    let token = localStorage.getItem("token");

    this.jobService.deleteBooking(delObj, token).subscribe(
      (posRes) => {
        localStorage.removeItem("bookingID");
        if (posRes.response == 3) {
          const txtmsg1 = this.translateService.instant("Your booking deleted..");
          this.openSnackBar(txtmsg1, '');
          this.router.navigateByUrl("/bookings");
          this.userService.isBookingDeleted.next(true);
        } else {
          const txtmsg = posRes.message;
          const txtmsg1 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg1, '');;
        }
      },
      (err: HttpErrorResponse) => {
        localStorage.removeItem("bookingID");
        const txtmsg = err.message.trim();
        const txtmsg2 = this.translateService.instant(txtmsg);
        this.openSnackBar(txtmsg2, '');
        if (err.error instanceof Error) {
          console.warn("Client Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      }
    );
  }
  showAttachmentPop(image) {
    let dialogRef = this.dialog.open(AttachmentSwiperComponent, {
      panelClass: "col-md-4",
      hasBackdrop: true,
      disableClose: false,
      data: image,
      height: "50vh",
    });
  }
  selectTasker(event, tasker, i) {
    if (event.checked) {
      let obj = {
        offeredUserID: tasker,
      };
      this.selectedTaskers.push(obj);
    } else {
      this.selectedTaskers.splice(i, 1);
    }
  }
  completeTask() {
    let modalText = "";
    if (this.iamPoster) {
      if (!this.selectedTaskers.length) {
        const txtmsg1 = this.translateService.instant("Please Select Taskers To Complete");
        this.openSnackBar(txtmsg1, '');
        return;
      }
      modalText = "Request for send payment to provider..";
    } else {
      modalText = "Request for release payment from Customer..";
    }
    let dailogRef = this.dialog.open(CompleteTaskDialogComponent, {
      panelClass: "col-md-3",
      hasBackdrop: true,
      disableClose: true,
      data: modalText,
    });
    dailogRef.afterClosed().subscribe((res) => {
      if (res) {
        let obj = {};
        if (this.iamPoster) {
          obj = {
            bookingID: this.rawData.bookingID,
            bookedTaskers: this.selectedTaskers,
            isCompletedByPoster: true,
            isTaskerCompletedTask: true,
          };
          this.completeByPoster(obj);
        } else {
          obj = {
            bookingID: this.rawData.bookingID,
            bookedTaskers: [
              {
                offeredUserID: this.bookedTaskers.offeredUserID,
              },
            ],
            isCompletedByPoster: false,
            isTaskerCompletedTask: true,
          };
          this.completeByTasker(obj);
        }
      }
    });
  }

  completeByTasker(details) {
    let token = localStorage.getItem("token");
    if (details.bookedTaskers.length >= 1) {
      this.loginService.showLoader.next(true);
      this.userService.completeTaskByTasker(details, token).subscribe(
        (posRes) => {
          if (posRes.response == 3) {
            this.loginService.showLoader.next(true);
            const txtmsg = posRes.message;
            const txtmsg1 = this.translateService.instant(txtmsg);
            this.openSnackBar(txtmsg1, '');;
            this.getTaskDetails();
          } else {
            this.loginService.showLoader.next(true);
            const txtmsg = posRes.message;
            const txtmsg1 = this.translateService.instant(txtmsg);
            this.openSnackBar(txtmsg1, '');;
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.loginService.showLoader.next(true);
            const txtmsg1 = this.translateService.instant("Server Failure try after some time..");
            this.openSnackBar(txtmsg1, '');
            console.warn("CSE", err.message);
          } else {
            console.warn("SSE", err.message);
          }
        }
      );
    } else {
      const txtmsg1 = this.translateService.instant("Please Select Taskers To Complete");
      this.openSnackBar(txtmsg1, '');
    }
  }
  completeByPoster(details) {
    let token = localStorage.getItem("token");
    if (details.bookedTaskers.length >= 1) {
      this.userService.completeTaskByPoster(details, token).subscribe(
        (posRes) => {
          if (posRes.response == 3) {
            const txtmsg = posRes.message;
            const txtmsg1 = this.translateService.instant(txtmsg);
            this.openSnackBar(txtmsg1, '');;
            this.getTaskDetails();
          } else {
            const txtmsg = posRes.message;
            const txtmsg1 = this.translateService.instant(txtmsg);
            this.openSnackBar(txtmsg1, '');;
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.warn("CSE", err.message);
          } else {
            console.warn("SSE", err.message);
          }
        }
      );
    } else {
      const txtmsg2 = this.translateService.instant("Please Select Taskers To Complete");
      this.openSnackBar(txtmsg2, '');
    }
  }
  withdraw(details) {
    let message = "You want to withdraw from this Job?";
    let obj: Withdraw = {
      bookingID: this.rawData.bookingID,
      offeredUserID: details.offeredUserID,
      isWithDraw: true,
      withDrawnByPoster: true,
      withDrawReason: "",
    };
    if (this.iamPoster) {
      message = "Do you want withdraw him/her from this job?";
      obj = {
        bookingID: this.rawData.bookingID,
        offeredUserID: details.offeredUserID,
        isWithDraw: true,
        withDrawnByPoster: true,
        withDrawReason: "",
      };
    } else {
      obj = {
        bookingID: this.rawData.bookingID,
        offeredUserID: this.user.userID,
        isWithDraw: true,
        withDrawnByPoster: false,
        withDrawReason: "",
      };
    }
    let data = {
      isTaskWithdraw: false,
      message: message,
    };
    let dailogRef = this.dialog.open(WithdrawComponent, {
      panelClass: "col-md-3",
      hasBackdrop: true,
      disableClose: true,
      data: message,
    });
    dailogRef.afterClosed().subscribe((res) => {
      if (res.withdraw) {
        let payLoad = { ...obj };
        payLoad.withDrawReason = res.reason;
        let token = localStorage.getItem("token");
        this.userService.withdrawFromJob(payLoad, token).subscribe(
          (posRes) => {
            if (posRes.response == 3) {
              const txtmsg = posRes.message;
              const txtmsg1 = this.translateService.instant(txtmsg);
              this.openSnackBar(txtmsg1, '');;
              this.getTaskDetails();
            } else {
              const txtmsg = posRes.message;
              const txtmsg1 = this.translateService.instant(txtmsg);
              this.openSnackBar(txtmsg1, '');;
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.warn("CSE", err.message);
            } else {
              console.warn("SSE", err.message);
            }
          }
        );
      }
    });
  }
  giveRatings(details) {
    let obj = {};
    if (this.iamPoster) {
      obj = {
        postTitle: this.rawData.taskTitle,
        iamPoster: true,
        bookingID: this.rawData.bookingID,
        postID: this.rawData.postID,
        body: "",
        ratingsAsAProvider: 0,
        ratingsGivenBy: {
          userID: this.user.userID,
          name: this.rawData.customerName,
          profilePic: this.rawData.customerProfilePic,
        },
        ratingsGivenTo: {
          userID: details.offeredUserID,
          name: details.offeredUserName,
          profilePic: details.offeredUserProfilePic,
        },
      };
    } else {
      obj = {
        postTitle: this.rawData.taskTitle,
        iamPoster: false,
        bookingID: this.rawData.bookingID,
        postID: this.rawData.postID,
        body: "",
        ratingsAsAPoster: 0,
        ratingsGivenBy: {
          userID: details.offeredUserID,
          name: details.offeredUserName,
          profilePic: details.offeredUserProfilePic,
        },
        ratingsGivenTo: {
          userID: this.rawData.userID,
          name: this.rawData.customerName,
          profilePic: this.rawData.customerProfilePic,
        },
      };
    }
    let dailogRef = this.dialog.open(GiveRatingsComponent, {
      panelClass: "col-md-4",
      hasBackdrop: true,
      disableClose: true,
      data: obj,
    });
    dailogRef.afterClosed().subscribe((res) => {
      if (res != undefined) {
        if (res.response == 3) {
          const txtmsg = res.message.trim();
          const txtmsg21 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg21, '');
          this.getTaskDetails();
        } else {
          const txtmsg = res.message.trim();
          const txtmsg2 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg2, '');
        }
      }
    });
  }
  shareOnFaceBook() {
    this.faceBookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
      this.shareUrl
    )}?t=${encodeURIComponent(this.shareTaskTitle)}`;
    window.open(this.faceBookUrl, "_blank");
  }
  shareOnTwiter() {
    this.twitterUrl = `https://twitter.com/share?url=${encodeURIComponent(
      this.shareUrl
    )}&amp;text=${encodeURIComponent(
      this.shareTaskTitle
    )}&amp;via=fabienb&amp;hashtags=koandesign`;
    window.open(this.twitterUrl, "_blank");
  }
  shareOnLinkedIn() {
    this.linkedInUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
      this.shareUrl
    )}&amp;title=${encodeURIComponent(this.shareTaskTitle)}`;
    window.open(this.linkedInUrl, "_blank");
  } 
}
