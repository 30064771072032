import { Component, OnInit, Inject } from '@angular/core';
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit {
coupounCode:any = "";
coupons:Array<any> = []
selectedLanguage: string;
  constructor(private dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar:MatSnackBar,
    private languageService: LanguageService,
    private translateService: TranslateService) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;      
    });
   }

  ngOnInit() {
    this.coupons = this.data;
    this.coupons.forEach(val=>{
      return val.applied == false;
    })    
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  getColors(index){
    switch (index%5){
      case 1:
        return '#52453C';
        case 2: return '#F8914B';
        case 3: return '#9CC165';
        case 4: return '#5478AF';
        case 0: return '#27384a';
    }
  }
  selectCoupon(coupon){
    this.coupounCode = coupon.couponCode 
  }
      //message alerts showing
 openSnackBar(message: string, action: string) {
  this.snackBar.open(message, action, {
     duration: 3000,
     panelClass:"text-white",
     verticalPosition:'top'
  });
}
  applyCoupon(coupon){
    if(coupon != ""){
      let index = -1;
   index = this.coupons.findIndex(val=>{
      return val.couponCode == coupon;
    })
    if(index != -1){
      this.dialogRef.close(this.coupons[index])
    }else{
      const txtmsg1 =  this.translateService.instant("Invalid Coupon Code..");          
          this.openSnackBar(txtmsg1, ''); 
    }
    }else{
      const txtmsg =  this.translateService.instant("Enter Coupon Code");          
      this.openSnackBar(txtmsg, '');
    }
  }
  confirmCoupon(coupon){
    this.dialogRef.close(coupon)
  }
  closeTab(){
    this.dialogRef.close()
  }
}
