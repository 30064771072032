import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DigitalHiringComponent } from "../digital-hiring/digital-hiring.component";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { LoginService } from "src/app/services/login.service";
import { JobsService } from "src/app/services/jobs.service";

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
@Component({
  selector: "app-add-ehire-category",
  templateUrl: "./add-ehire-category.component.html",
  styleUrls: ["./add-ehire-category.component.css"],
})
export class AddEhireCategoryComponent implements OnInit {
  addCatForm: FormGroup;
  selectedLanguage: string;
  category: any = [];
  constructor(
    private dialogRef: MatDialogRef<DigitalHiringComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private languageService: LanguageService,  private loginService: LoginService,
    private jobsService : JobsService) {
      this.languageService.selectedLanguage$.subscribe(language => {
        this.selectedLanguage = language;      
      });
     }

  ngOnInit() {
    
    this.addCatForm = this.fb.group({
      categoryName: ["", Validators.required],
      Experience: ["", [Validators.required, Validators.min(1)]],
      isSelected: [true],
    });
    // this.browseCategory();
    this.category = this.data;
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  submitDetails() {
    if (!this.addCatForm.valid) {
      return;
    }
    this.dialogRef.close(this.addCatForm.value);
  }
  close() {
    this.dialogRef.close();
  }

  
  
}
