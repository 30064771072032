
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Pipe({
  name: 'dateAgo1',
  pure: true
})
export class DateAgo1Pipe implements PipeTransform {  
  selectedLanguage: string;
  languageCode : string;
  constructor(   
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {   
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;      
    });
  }
  ngOnInit() {
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  


transform(dateString: string, languageCode: string): string {
  const timeDifference = Date.now() - Date.parse(dateString);
  let timeAgo: string;

  languageCode = this.selectedLanguage 

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);
  const years = Math.floor(days / 365);
    if (years > 0) {
      timeAgo = years + ' 幾年前';
    } else if (weeks > 0) {
      timeAgo = weeks + ' 幾週前';
    } else {
      timeAgo = days + ' 幾天前';
    }
  

  return timeAgo;
}
}
