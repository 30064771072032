import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JobsService {
  public isJobPosted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public baseUrl: string = "https://india-live-api.talentasker.com";
  
  constructor(private http: HttpClient) { }
  checkStatus(data): Observable<any> {
    const myheader = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded" 
    );
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append("Amount", data.Amount);
    urlSearchParams.append("CurrencyCode", data.CurrencyCode);
    urlSearchParams.append("HashValue", data.HashValue);
    urlSearchParams.append("PaymentID", data.PaymentID);
    urlSearchParams.append("PymtMethod", data.PymtMethod);
    urlSearchParams.append("ServiceID", data.ServiceID);
    urlSearchParams.append("TransactionType", data.TransactionType);
    let body = urlSearchParams.toString();
    return this.http.post(
      "https://securepay.e-ghl.com/IPG/payment.aspx",
      body,
      { headers: myheader, responseType: "text" }
    );   
  }
 
  browsJobs(data,token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/postjob/fetch_all`,
      data,
      { headers: { talentasker: token } }
    );
  }
  getLat(data): Observable<any> {
    return this.http.get(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        data +
        "&sensor=false&components=country:IN&key=AIzaSyC3WXQDiGGHZ3e836OvmlFhPf70DwBpKSE"
    );
  }
  messageReadUpdate(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/offers/isReadMessageUpdate`,
      data,
      { headers: { talentasker: token } }
    );
  }
  searchTermJobs(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/postjob/browsejob`,
      data,
      { headers: { talentasker: token } }
    );
  }
  browseCategory(): Observable<any> {
    return this.http.get(`${this.baseUrl}/api/categories`);
  }
  postJobs(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/postjob/insertPostJob`,
      data,
      { headers: { talentasker: token } }
    );
  }
  getPhonePeInit(obj,token){
    return this.http.post(
      `${this.baseUrl}/api/hire/PhonePayPaymentsinit`,obj);
  }
  getPhonePePaymentStatus(obj,token){
    return this.http.post(`${this.baseUrl}/api/hire/PhonePayPaymentStatus`,obj);
  }
  withDrawOffer(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        talentasker: token,
      }),
      body: {
        postID: data.postID,
        offeredUserID: data.offeredUserID,
      },
    };
    return this.http.delete(
      `${this.baseUrl}/api/offers/delete_offer`,
      options
    );
  }
  replyToUser(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/postjob/replay_to_offereduser`,
      data,
      { headers: { talentasker: token } }
    );
  }
  partialHire(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/hire/partialBooking`,
      data,
      { headers: { talentasker: token } }
    );
  }
  fullHire2(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/hire/taskers`,
      data,
      { headers: { talentasker: token } }
    );
  }
  fullHire(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/hire/hireTasker`,
      data,
      { headers: { talentasker: token } }
    );
  }
  deleteBooking(data, token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        talentasker: token,
      }),
      body: {
        bookingID: data.bookingID,
      },
    };
    return this.http.delete(
      `${this.baseUrl}/api/hire/partialBooking`,
      options
    );
  }
  editPost(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/postjob/updatePostJob`,
      data,
      { headers: { talentasker: token } }
    );
  }
  cancelTask(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/postjob/taskCancel`,
      data,
      { headers: { talentasker: token } }
    );
  }
  getMyTasks(data,token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/postjob/get`,
      data,
      { headers: { talentasker: token } }
    );
  }
  getMyOfferedTasks(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/offers/getOfferedPosts`,
      data,
      { headers: { talentasker: token } }
    );
  }
  getFilteredJobs(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/customer/task`,
      data,
      { headers: { talentasker: token } }
    );
  }
  updatePhoto(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/customer/update`,
      data,
      { headers: { talentasker: token } }
    );
  }
  updateMobileNumber(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/customer/updatemobileno`,
      data,
      { headers: { talentasker: token } }
    );
  }
  updateMobileStatus(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/customer/updatemobileverify`,
      data,
      { headers: { talentasker: token } }
    );
  }
  reportTask(data,token) {
    return this.http.post(
      `${this.baseUrl}/api/task/insert`,
      data,
      { headers: { talentasker: token } }
    );
  }
  updateDob(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/customer/updatedob`,
      data,
      { headers: { talentasker: token } }
    );
  }
  updateAddress(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/customer/updateaddress`,
      data,
      { headers: { talentasker: token } }
    );
  }
  updateBankAccount(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/customer/updateaccount`,
      data,
      { headers: { talentasker: token } }
    );
  }
  makeAnOffer(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/postjob/insertoffers`,
      data,
      { headers: { talentasker: token } }
    );
  }
  updateAnOffer(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/offers/update_offer`,
      data,
      { headers: { talentasker: token } }
    );
  }
  UpdateBooking(data, token): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/api/hire/updatebookinID`,
      data,
      { headers: { talentasker: token } }
    );
  }
  addComments(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/postjob/add_comment`,
      data,
      { headers: { talentasker: token } }
    );
  }
  languageConvert(data, token): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/api/AccountVerify/languageConvert`,
      data,
      { headers: { talentasker: token } }
    );
  }
}
