import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HeaderComponent } from "../header/header.component";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar, } from "@angular/material";
import { AuthService, FacebookLoginProvider, SocialUser, GoogleLoginProvider, } from "angularx-social-login";
import { LoginService } from "src/app/services/login.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { SetPasswordComponent } from "../set-password/set-password.component";
import { AccountAlertDialogComponent } from "../account-alert-dialog/account-alert-dialog.component";
import { PushMessagingService } from "src/app/services/push-messaging.service";
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from "src/app/services/admin.service";
import { countryDataInfo } from '../../countryInfo';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  user: SocialUser;
  loggedIn: boolean;
  loading = false;
  socialLoginForm: FormGroup;
  hide = true;
  lat: any = "3.091210";
  lang: any = "101.677101";
  selectedLanguage: string;
  public countryData = countryDataInfo;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<HeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private pushMessageService: PushMessagingService,
    private pushService: PushMessagingService,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    // this.getLatLang();
    this.socialLoginForm = this.fb.group({
      ID: [""],
      userID: [""],
      firstName: [""],
      lastName: [""],
      register_type: [""],
      latitude: [""],
      longitude: [""],
      tokenID: [""],
      profilePic: [""],
      deviceToken: [""],
      deviceType: ["web"],
    });
    this.loginForm = this.fb.group({
      // userID: ["", [Validators.required, Validators.email]],
      userID: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(8)]],
      deviceToken: [""],
      deviceType: ["web"],
    });

    // this.authService.authState.subscribe((user) => {
    //   this.user = user;
    //   this.loggedIn = user != null;
    //   console.log("user", this.user);
    //   if (this.user != null) {
    //     this.loggedIn = true;
    //     //this.dialogRef.close(this.user)
    //     this.pathchSocialLoginDetails(this.user);
    //     this.logout();
    //   } else {
    //     this.loading = false;
    //   }
    // });

    this.authService.authState.subscribe((user) => {
      this.user = user;
      console.log(user);
    });
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }

  closeTab() {
    this.dialogRef.close();
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }
  logout() {
    this.authService.signOut().then((data) => {
    });
  }
  pathchSocialLoginDetails(user) {
    this.loading = true;
    let providerType = user.provider.toLowerCase();
    let pType = providerType.charAt(0).toUpperCase() + providerType.slice(1);
    this.socialLoginForm.patchValue({
      ID: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      register_type: pType,
      latitude: this.lat,
      longitude: this.lang,
      tokenID: user.authToken,
      profilePic: user.photoUrl,
    });
    if (user.email == undefined) {
      this.socialLoginForm.patchValue({
        userID: "",
      });
    } else {
      this.socialLoginForm.patchValue({
        userID: user.email,
      });
    }

    if (!user.email) {
      const txtmsg1 = this.translateService.instant("This social media dont have email details");
      this.openSnackBar(txtmsg1, '');
      return;
    }
    let obj = { userID: user.email };
    this.loginService.checkUserAvailability(obj).subscribe(
      (res) => {
        if (res.isActive) {
          this.submitSocialMedia();
        } else {
          const txtmsg = res.message;
          const txtmsg2 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg2, '');
        }
      },
      (err: HttpErrorResponse) => {
        const txtmsg = err.message.trim();
        const txtmsg2 = this.translateService.instant(txtmsg);
        this.openSnackBar(txtmsg2, '');
        if (err.error instanceof Error) {
          console.log("Client Side Error", err.headers);
        } else {
          console.log("Server side Error", err.message);
        }
      }
    );
    // End Here
    let deviceID = this.pushService.fcmToken;
    if (deviceID != null) {
      this.socialLoginForm.patchValue({
        deviceToken: deviceID,
      });
    }
  }
  submitSocialMedia() {
    this.loginService.socialLogin(this.socialLoginForm.value).subscribe(
      (posRes) => {
        if (posRes.response == 3) {
          this.loading = false;
          // this.loginService.isManualLogin = false;
          localStorage.setItem("token", posRes.tokenID);
          if (posRes.customerInfo) {
            this.dialogRef.close(posRes);
          } else {
            this.dialogRef.close(this.socialLoginForm.value);
          }
        } else {
          this.loading = false;
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.loading = false;
          const txtmsg = err.message.trim();
          const txtmsg2 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg2, '');
        } else {
          this.loading = false;
          const txtmsg = err.message.trim();
          const txtmsg2 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg2, '');
        }
      }
    );
  }
  getLatLang() {
    this.loginService.getPosition().then((pos) => {
      this.lat = pos.lat;
      this.lang = pos.lng;
    });
  }
  submitDetails() {
    if (this.loginForm.valid) {
      let userid = this.loginForm.get("userID").value;
      let country = this.countryData[userid];
      if (country) {
        this.adminService.baseUrl = country.baseUrl;
        this.loading = true;
        this.adminService.getDefaultToken(userid).subscribe((resp: any) => {
          if (resp.response == 3) {
            let token = resp.access_token;
            let payload = this.loginForm.value;
            let obj1 = {
              userID: payload.userID,
              password: payload.password
            }
            this.adminService.adminLogin(obj1, token).subscribe((posRes: any) => {
              this.loading = false;
              if (posRes.response == 3) {
                localStorage.setItem("CountryInfo", JSON.stringify(country));
                if (posRes && posRes.adminType == "System administrator") {
                  sessionStorage.setItem('isMainAdmin', '1')
                } else {
                  sessionStorage.setItem('isMainAdmin', '0')
                }
                sessionStorage.setItem('token', posRes.access_token);
                let p = {
                  IsAdminLogin: true
                }
                localStorage.setItem('IsAdminLogin', JSON.stringify(p));
                let obj = {
                  isAdmin: true
                }
                this.dialogRef.close(obj);
                // this.router.navigate(['admin', 'allBookings'])
              } else {
                this.openSnackBar(posRes.message, "")
              }
            }, (err: HttpErrorResponse) => {
              this.loading = false;
              this.openSnackBar(err.message, "")
              if (err.error instanceof Error) {
                console.warn("Client Error", err.error)
              } else {
                console.warn("Server Error", err.error)
              }
            })
          } else {
            this.openSnackBar(resp.message, "");
          }
        })
      }
      else {
        this.loading = true;
        let payload = this.loginForm.value;
        let obj3 = {
          userID: payload.userID,
          password: payload.password
        }
        let deviceID = this.pushMessageService.fcmToken;
        if (deviceID != null) {
          this.loginForm.patchValue({
            deviceToken: this.pushMessageService.fcmToken,
          });
        }
        this.loginService.login(this.loginForm.value).subscribe(
          (posRes) => {
            if (posRes.response == 3) {
              this.loading = false;
              // this.loginService.isManualLogin = true;
              // this.loginService.faceBookLoginImg = false;
              localStorage.setItem("token", posRes.access_token);
              posRes["isAdmin"] = false;
              let p = {
                IsAdminLogin: false
              }
              localStorage.setItem('IsAdminLogin', JSON.stringify(p));
              this.dialogRef.close(posRes);
            } else if (
              posRes.message ==
              "You have already logged in from social media so please set your password"
            ) {
              this.openConfirmDialog();
            } else if (
              posRes.message == "No data found with us. Please register with us"
            ) {
              this.loading = false;
              this.openAlertDialog();
            } else if (
              posRes.response == 5 &&
              posRes.message ==
              "Customer is registered but verification is pending"
            ) {
              this.loading = false;
              this.openOTP();
            } else {
              this.loading = false;
              const txtmsg = posRes.message;
              const txtmsg1 = this.translateService.instant(txtmsg);
              this.openSnackBar(txtmsg1, '');;
            }
          },
          (err: HttpErrorResponse) => {
            this.loading = false;
            if (err.error instanceof Error) {
              const txtmsg = err.message.trim();
              const txtmsg2 = this.translateService.instant(txtmsg);
              this.openSnackBar(txtmsg2, '');
              console.log("CSE");
            } else {
              const txtmsg = err.message.trim();
              const txtmsg21 = this.translateService.instant(txtmsg);
              this.openSnackBar(txtmsg21, '');
              console.log("SSE");
            }
          }
        );
      }

    } else {
      const txtmsg2 = this.translateService.instant("Enter all required fields");
      this.openSnackBar(txtmsg2, '');
    }
  }

  openOTP() { }

  openAlertDialog() {
    let dailogRef2 = this.dialog.open(AccountAlertDialogComponent, {
      panelClass: "col-md-3",
      hasBackdrop: false,
      data: {
        userID: this.loginForm.value.userID,
        message: "No data found with us. Please register with us",
      },
    });
    dailogRef2.afterClosed().subscribe((res) => {
      if (res == true) {
        localStorage.setItem("userId", this.loginForm.value.userID);
        this.loginService.setUserId.next(true);
        this.openSignUp();
      }
    });
  }

  openConfirmDialog() {
    let dailogRef1 = this.dialog.open(SetPasswordComponent, {
      panelClass: "col-md-3",
      hasBackdrop: false,
      data: {
        userID: this.loginForm.value.userID,
        message:
          "You have already logged in from social media so please set your password",
      },
    });
    dailogRef1.afterClosed().subscribe((res) => {
      if (res == true) {
        this.dialogRef.close();
      }
    });
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  
  }
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  openSignUp() {
    this.loginService.callSignUp.next(true);
    this.dialogRef.close();
  }
  openForgotDialog() {
    this.dialogRef.close();
    this.loginService.callForgotPass.next(true);
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }


}
