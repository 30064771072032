import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-how-to-apply-job',
  templateUrl: './how-to-apply-job.component.html',
  styleUrls: ['./how-to-apply-job.component.css']
})
export class HowToApplyJobComponent implements OnInit {
  user: any;
  selectedLanguage: string;
  isLoggedIn: boolean = false;
  constructor(private loginService: LoginService, private router: Router,
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.isLoggedIn = this.user && this.user.userID ? true : false;
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  openPostJob() {
    if (this.isLoggedIn) {
      this.router.navigateByUrl('/browsejobs')
    } else {
      this.loginService.callLogin.next(true)
    }
  }
}
