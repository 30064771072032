import { Component, OnInit, Inject } from '@angular/core';
import { AccountVerificationComponent } from '../account-verification/account-verification.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-account-verify-status',
  templateUrl: './account-verify-status.component.html',
  styleUrls: ['./account-verify-status.component.css']
})
export class AccountVerifyStatusComponent implements OnInit {
  selectedLanguage: string;
  constructor(private dailogRef:MatDialogRef<AccountVerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private translateService: TranslateService,
    private languageService: LanguageService) {
      this.languageService.selectedLanguage$.subscribe(language => {
        this.selectedLanguage = language;      
      }); 
     }

  ngOnInit() {
    console.log(this.data);
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  closeTab(){
    let obj = {
      retry : false
    }
this.dailogRef.close(obj)
  }
  retry(){
    let obj = {
      retry : true
    }
this.dailogRef.close(obj)
  }
}
