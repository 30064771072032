import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  cInfo: any;
  selectedLanguage: string;
  index: number = 0;
  checkValue: boolean = false;
  loading: boolean = false;
  category: any = [];
 
  isDropdownOpen: boolean = false;
  selectCImg: string = "../../../assets/India.png";
  selectedText: string = "India";
  countries: any[];
  selectCountryObj: any;
  countryList = [
    {
      CountryName: 'India',
      countryCode: 'IN',
      currency: 'INR',
      Nationality: "Indian",
      CurrencySymbol: "₹",
      baseUrl: 'https://india-live-api.talentasker.com',
      imageURL: "../../../assets/India.png",
      Home_Url: "https://india-live-api.talentasker.com/",
      DynamicLink: "https://talentaskerin.page.link",     

    },
    {
      CountryName: 'Taiwan',
      countryCode: 'TW',
      currency: 'TWD',
      Nationality: "Taiwanese",
      CurrencySymbol: "NT$",
      baseUrl: 'https://taiwan-live-api.talentasker.com',
      imageURL: "../../../assets/Taiwan.png",
      Home_Url: "https://taiwan-live-api.talentasker.com/",
      DynamicLink: "https://talentaskerin.page.link",     
    },
    {
      CountryName: 'Malaysia',
      countryCode: 'MY',
      currency: 'MYR',
      Nationality: "Malaysian",
      CurrencySymbol: "RM",
      baseUrl: 'https://malyasia-live-api.talentasker.com',
      imageURL: "../../../assets/Malaysia.png",
      Home_Url: "https://malyasia-live-api.talentasker.com/",
      DynamicLink: "https://talentaskermy.page.link"

    }, {
      CountryName: 'Nigeria',
      countryCode: 'NG',
      currency: 'NGN',
      Nationality: "Nigerian",
      CurrencySymbol: "₦",
      baseUrl: 'https://naizeria-live-api.talentasker.com',
      imageURL: "../../../assets/Nigeria.png",
      Home_Url: "https://naizeria-live-api.talentasker.com/",
      DynamicLink: "https://talentasker.page.link"

    }, {
      CountryName: 'Thailand',
      countryCode: 'TH',
      currency: 'THB',
      Nationality: "Thai",
      CurrencySymbol: "฿",
      baseUrl: 'https://phuket-live-api.talentasker.com',
      imageURL: "../../../assets/Thailand.png",
      Home_Url: "https://phuket-live-api.talentasker.com/",
      DynamicLink: "https://talentaskerth.page.link"
    }
  ]
  
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.countries =  this.countryList;
    this.cInfo =  JSON.parse(localStorage.getItem('CountryInformation'));      
    this.selectCImg =  this.cInfo.imageURL;
    this.selectedText =  this.cInfo.CountryName;    
  }

  selectOption(option: any) {
    this.selectCImg = option.imageURL;
    this.selectedText = option.CountryName;
    this.isDropdownOpen = !this.isDropdownOpen;
  } 

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}

