import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-complete-task-dialog',
  templateUrl: './complete-task-dialog.component.html',
  styleUrls: ['./complete-task-dialog.component.css']
})
export class CompleteTaskDialogComponent implements OnInit {
  selectedLanguage: string;
  constructor(private dialogRef: MatDialogRef<BookingDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private languageService: LanguageService,
    private translateService: TranslateService,) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;      
    });
   }

  ngOnInit() {
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  submit(){
    this.dialogRef.close(true)
  }
  cancel(){
    this.dialogRef.close(false)
  }
}
