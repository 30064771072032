import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HeaderComponent } from '../header/header.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.css']
})
export class SignOutComponent implements OnInit {
  selectedLanguage: string;
  constructor(private dialogRef: MatDialogRef<HeaderComponent>,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  submit() {
    this.dialogRef.close(true)
  }
  close() {
    this.dialogRef.close(false)
  }
}
