
export const environment = {
  production: false,
  firebase: {  
    apiKey: "AIzaSyC3WXQDiGGHZ3e836OvmlFhPf70DwBpKSE",
    authDomain: "talentasker-a4c3f.firebaseapp.com",
    projectId: "talentasker-a4c3f",
    storageBucket: "talentasker-a4c3f.appspot.com",
    messagingSenderId: "772166499132",
    appId: "1:772166499132:web:58f2d68b247b0b73023c39",
    measurementId: "G-BJ6Z25JCC1"
  }
};