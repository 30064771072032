import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InboxComponent } from '../inbox/inbox.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-inbox-messages',
  templateUrl: './inbox-messages.component.html',
  styleUrls: ['./inbox-messages.component.css']
})
export class InboxMessagesComponent implements OnInit {
  inboxForm:FormGroup;
  isInboxChat:boolean = false;
  notificationData:any;
  selectedLanguage: string;
  constructor(private fb:FormBuilder, private dailogRef:MatDialogRef<InboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private translateService: TranslateService,
    private languageService: LanguageService) {
      this.languageService.selectedLanguage$.subscribe(language => {
        this.selectedLanguage = language;      
      });
     }

  ngOnInit() {
    this.notificationData = this.data.notification
    this.inboxForm = this.fb.group({
      message:[""]
    })
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  closeTab(){
    this.dailogRef.close()
  }
  sendReply(){
    this.closeTab()
  }
}
