import { Component, OnInit, Inject } from '@angular/core';
import { RewardsComponent } from '../rewards/rewards.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-rewards-dailog',
  templateUrl: './rewards-dailog.component.html',
  styleUrls: ['./rewards-dailog.component.css']
})
export class RewardsDailogComponent implements OnInit {
  selectedLanguage: string;
  constructor(private dialogRef: MatDialogRef<RewardsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private languageService: LanguageService) {
      this.languageService.selectedLanguage$.subscribe(language => {
        this.selectedLanguage = language;      
      });
     }

  ngOnInit() {
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
closeTab(){
  this.dialogRef.close()
}
}
