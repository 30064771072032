import { Component, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { MatSidenav } from "@angular/material";
import { LoginService } from "./services/login.service";
import { PushMessagingService } from "./services/push-messaging.service";
import * as SmartBanner from "../../node_modules/smart-app-banner/dist/smart-app-banner.js";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { AdminService } from "./services/admin.service";
import { BnNgIdleService } from 'bn-ng-idle';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "talentakserwebapp";
  isLoggedIn = false;
  loading: boolean = false;
  refferObj: any;
  SmartBanner: any;
  @ViewChild("sidenav", { static: true }) sideNav: MatSidenav;
  @ViewChild("sideNavContainer", { static: true }) sideNavContainer: ElementRef;
  supportedLanguages = [
    { code: 'en', label: 'English' },
    { code: 'zh', label: '中國傳統的' }
  ];
  selectedLanguage: string;
  countryCode: any;
  lat: any;
  lang: any;
  selectCountryInfo: any;
  Findurl: boolean = true;
  isUrl: string = "";
  AllCountyList = [
    {
      CountryName: 'India',
      countryCode: 'IN'
    },
    {
      CountryName: 'Taiwan',
      countryCode: 'TW'
    },
    {
      CountryName: 'Malaysia',
      countryCode: 'MY'
    }, {
      CountryName: 'Nigeria',
      countryCode: 'NG'
    }, {
      CountryName: 'Thailand',
      countryCode: 'TH'
    }
  ]
  token: string;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private messagingService: PushMessagingService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private bnIdle: BnNgIdleService,
  ) {
    new SmartBanner({
      appStoreLanguage: "us", // language code for the App Store (defaults to user's browser language)
      title: "Open with Talentasker App",
      author: "talentasker.com",
      button: "VIEW",
      bgColor: "#fff",
      store: {
        ios: "On the App Store",
        android: "In Google Play",
        windows: "In Windows store",
      },
      price: {
        ios: "FREE",
        android: "FREE",
        windows: "FREE",
      },
      theme: "ios", // put platform type ('ios', 'android', etc.) here to force single theme on all device
      icon: "../assets/tsicon.png", // full path to icon image if not using website icon image
      // , force: 'ios' // Uncomment for platform emulation
    });
    this.loginService.showLoader.subscribe((flag: boolean) => {
      if (this.loading !== flag) {
        this.loading = flag;
      }
    });
    this.loginService.findurl.subscribe((value) => {
      this.Findurl = value;
    });
    this.loginService.checkIsLoggedIn.subscribe((val) => {
      if (val == true) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
    this.loginService.checkIsAdminLogedout.subscribe((val) => {
      if (val == true) {
        this.logoutAdmin();
      }
    })
    this.loginService.checkIsAdminLoggedIn.subscribe((val) => {
      if (val == true) {
        this.Findurl = false;
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    window.onscroll = () => {
      this.scrollCheck();
    };
  }
  ngOnInit() {
    this.token = sessionStorage.getItem('token');

    if (this.token == null) {
      this.Findurl = true;
    }
    else {
      this.Findurl = false;
    }
    this.getLatLang();
    this.initializeSmartAppBanner();
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.refferObj = { ...params };
    });

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }
  logoutAdmin() {
    this.Findurl = true;
    this.loginService.checkIsAdminLogedout.next(false);
    this.router.navigateByUrl('/home');
  }

  closedStart() {
    document.body.style.overflow = "auto";
  }
  initializeSmartAppBanner() { }
  openedStart() {
    document.body.style.overflow = "hidden";
  }
  toggleSideNav() {
    this.sideNav.toggle();
    setTimeout(() => {
      // const sideNavContainer = document.getElementById('sideNavContainer');
      // if (sideNavContainer && sideNavContainer.classList.contains('mat-drawer-container-has-open')) {
      //   document.body.style.overflow = 'hidden';
      // } else {
      //   document.body.style.overflow = 'auto';
      // }
    }, 2000);
  }

  scrollCheck() {
    let mybutton = document.getElementById("myBtn");
    if (
      document.body.scrollTop > 300 ||
      document.documentElement.scrollTop > 300
    ) {
      mybutton.style.opacity = "1";
    } else {
      mybutton.style.opacity = "0";
    }
  }
  scroll() {
    window.scroll(0, 0);
  }
  toggle() {
    this.sideNav.toggle();
  }
  openLogin() {
    this.toggleSideNav();
    this.loginService.callLogin.next(true);
  }
  openSignUp() {
    this.toggleSideNav();
    this.loginService.callSignUp.next(true);
  }
  openEhire() {
    this.toggleSideNav();
    this.loginService.callEhire.next(true);
  }
  openSignOut() {
    this.toggleSideNav();
    this.loginService.callSignOut.next(true);
  }
  openPostJob() {
    this.toggleSideNav();
    this.loginService.callPostJob.next(true);
  }
  changeLang(language: string) {
    this.languageService.setSelectedLanguage(language);
    this.switchLanguage(language)
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }

  getLatLang() {
    this.loginService.getPosition().then((pos) => {
      this.lat = pos.lat;
      this.lang = pos.lng;
      let obj = { longitude: pos.lng, latitude: pos.lat };
      this.getPosition(obj);
    });
  }

  getPosition(position) {
    this.loginService.getLocation(position).subscribe(
      (pos) => {
        if (pos && pos.status === 'OK') {
          const result = pos.results[0];
          for (const component of result.address_components) {
            if (component.types.includes('country')) {
              this.countryCode = component.short_name;
              console.log(this.countryCode);
              break;
            }
          }
          switch (this.countryCode) {
            case "TW":
              window.location.href = 'https://tw.talentasker.com/';
              break;
            case "NG":
              window.location.href = 'http://ngn.talentasker.com/';
              break;
          }

          // let matchFound = false;
          // // Loop through AllCountyList to find a match
          // for (const item of this.AllCountyList) {
          //   if (item.countryCode === this.countryCode) {
          //     this.selectCountryInfo = item;
          //     console.log("It is our country", this.selectCountryInfo);
          //     matchFound = true; 
          //     break;
          //   }
          // }
          // if (!matchFound) {         
          //   if (this.countryCode === 'US') {
          //     window.location.href = 'https://www.softexer.com/';
          //   }
          // }
        }
      },
      (err) => {
        console.log("ERR", err);
      }
    );
  }
}
