import { Component, OnInit, Inject } from '@angular/core';
import { MyTaskDetailsComponent } from '../../my-task-details/my-task-details.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {
  isSuccess: boolean = false;
  selectedLanguage: string;
  imgPath: string;
  constructor(private dialogRef: MatDialogRef<MyTaskDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    if (this.data.isSuccess) {
      this.imgPath = "assets/ticked.png"
      this.isSuccess = true;
      setTimeout(() => {
        this.closeTab()
      }, 3000)
    } else {
      this.imgPath = "assets/paymentcancel.png"
      this.isSuccess = false;
      setTimeout(() => {
        this.closeTab()
      }, 3000)
    }
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  
  closeTab() {
    let obj = {
      retry: false
    }
    this.dialogRef.close(obj)
  }
  retryPayment() {
    let obj = {
      retry: true
    }
    this.dialogRef.close(obj);
  }
}
