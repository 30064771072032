import { Component, OnInit, Inject } from '@angular/core';
import { UserServiceService } from 'src/app/services/user-service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfileComponent } from '../../user-profile/user-profile.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-emergency-contacts',
  templateUrl: './emergency-contacts.component.html',
  styleUrls: ['./emergency-contacts.component.css']
})
export class EmergencyContactsComponent implements OnInit {
emergencyContacts:Array<any> = [];
isContactsShowing:boolean = true;
emergencyContactsForm:FormGroup;
isAddContact:boolean = true;
countryCode = {initialCountry: ''}
id:string = "";
loading:boolean = false;
selectedLanguage: string;
NameoftheContactPerson: string;
formSubmitted: boolean = false;
btnText: string = "Submit Contact";

  constructor(private userService:UserServiceService,private fb:FormBuilder, private dailogRef:MatDialogRef<UserProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any, private loginService:LoginService, private translateService: TranslateService,
    private languageService: LanguageService) {
      this.languageService.selectedLanguage$.subscribe(language => {
        this.selectedLanguage = language;      
      });
     }

  ngOnInit() {
    this.countryCode.initialCountry = 'in'
    this.callForm()
    this.fetchContacts()
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  callForm(){
    this.emergencyContactsForm= this.fb.group({
      userID:[""],
      // NameoftheContactPerson:["",Validators.required],
      // contact: this.fb.group({
      //   phoneNumber:["",[Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      NameoftheContactPerson: ['', [Validators.required]],
    contact: this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],    
        countryCode:["IN",]
      })
    })
  }
  closeTab(){
    this.dailogRef.close()
  }
  deleteContact(id,i){
    let obj = {
      _id : id
    }
    let token = localStorage.getItem('token')
    this.userService.deleteEmergencyContacts(obj,token).subscribe((posRes)=>{
      if(posRes.response == 3){
       this.emergencyContacts.splice(i,1)
      }
    },(err:HttpErrorResponse)=>{
      if(err.error instanceof Error){
        console.warn("CSE",err.message);
      }else{
        console.warn("ServerSideError",err.message);
      }
    })
  }
  backtoForm(){
    this.btnText = "Submit Contact";
    let ctn = {
      phoneNumber :'',
      countryCode :"IN"
    }
    this.emergencyContactsForm.patchValue({
      NameoftheContactPerson :"",
      contact :ctn
    });
    this.isContactsShowing = !this.isContactsShowing;
    this.formSubmitted = false;
  }
  editNumber(contact){
    this.btnText = "Update Contact"
    this.isContactsShowing = false;
    this.isAddContact = false;
    this.id = contact._id;
    this.emergencyContactsForm.patchValue({
      NameoftheContactPerson: contact.NameoftheContactPerson,
      contact:{
        phoneNumber : parseFloat(contact.contact.phoneNumber),
        countryCode : contact.contact.countryCode
      }
    })
    console.log(contact)
    this.NameoftheContactPerson = contact.NameoftheContactPerson;
    this.countryCode.initialCountry = contact.contact.countryCode.toLowerCase()
  }
  fetchContacts(){
    this.loading = true;
    this.loginService.showLoader.next(true);
    let obj = {userID : this.data.userID};
    let token = localStorage.getItem('token')
    console.log(obj);
    this.userService.fetchEmergencyContacts(obj,token).subscribe((posRes)=>{
      this.loading = false;
      if(posRes.response == 3){
        this.loginService.showLoader.next(false);
        this.emergencyContacts = posRes.contacts;
      }else{
        this.loginService.showLoader.next(false);
        alert(posRes.message);
      }
    },(err:HttpErrorResponse)=>{
      this.loginService.showLoader.next(false);
      this.loading = false;
      if(err.error instanceof Error){
        console.warn("CSE",err.message);
      }else{
        console.warn("ServerSideError",err.message);
      }
    })
  }
  sendContactNumbers(){   
    if (this.emergencyContactsForm.valid) {
      if(this.isAddContact){
        this.emergencyContactsForm.get('userID').setValue(this.data.userID);
        this.submitContacts()
      }else{
        let payLoad = {...this.emergencyContactsForm.value}
        delete payLoad.userID
         payLoad._id = this.id;
         payLoad.contact.phoneNumber =""+payLoad.contact.phoneNumber
         this.submitEditedContacts(payLoad)
      }
    }
    this.formSubmitted = true;
   
  }
  numericOnly(event: KeyboardEvent) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.key)) {
      event.preventDefault(); // Prevent input if it's not a number
    }
  }
  
  submitEditedContacts(payLoad){
    this.loginService.showLoader.next(true);
    let token = localStorage.getItem('token')
    this.userService.editEmergencyContacts(payLoad,token).subscribe((posRes)=>{
      this.loginService.showLoader.next(false);
      if(posRes.response == 3){
        this.backtoForm();
        this.fetchContacts()
        this.isContactsShowing = true;
      }else{
        alert(posRes.message)
      }
    },(err:HttpErrorResponse)=>{
      this.loginService.showLoader.next(false);
      if(err.error instanceof Error){
        console.warn("CSE",err.message);
      }else{
        console.warn("ServerSideError",err.message);
      }
    })
  }
  onCountryChange(event){
    let code = event.iso2
    console.log(code.toUpperCase());
    
    this.emergencyContactsForm.patchValue({
      contact:{
       countryCode : code.toUpperCase()
      }
    })
  }
  submitContacts(){
     if(this.emergencyContactsForm.valid){
      let token = localStorage.getItem('token');
      this.userService.addEmergencyContacts(this.emergencyContactsForm.value,token).subscribe((posRes)=>{
        if(posRes.response == 3){
          this.backtoForm();
          this.fetchContacts()
          this.isContactsShowing = true;
         this.callForm()
        }
      },(err:HttpErrorResponse)=>{
        if(err.error instanceof Error){
          console.warn("CSE",err.message);
        }else{
          console.warn("ServerSideError",err.message);
        }
      })
    }
  }
}
