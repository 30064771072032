import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { NavigationEnd, ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { JobsService } from "src/app/services/jobs.service";
import { LoginService } from "src/app/services/login.service";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  categoriesList: Array<any> = [];
  splitedCategories: Array<any> = [];
  loggedIn: boolean;
  selectedLanguage: string;
  constructor(
    private loginService: LoginService,
    private jobService: JobsService,
    private snackBar: MatSnackBar,
    private router:Router,
    private translateService: TranslateService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loginService.checkIsLoggedIn.subscribe((val) => {
      if (!val) {
        this.loggedIn = false;
      } else {
        this.loggedIn = true;
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // window.scrollTo(0, 0);
      window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });  
    });
    
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;      
    });
  }

  ngOnInit() {
    this.getCategories();
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }

  getCategories() {
    if (
      this.loginService.categoriesList &&
      this.loginService.categoriesList.categoriesList
    ) {
      this.categoriesList = this.loginService.categoriesList.categoriesList;
      let data = [...this.categoriesList];
      const n = 3;
      const result = [[], [], []]; 
      const catPerLine = Math.ceil(data.length / 3);
      for (let line = 0; line <= n; line++) {
        for (let i = 0; i < catPerLine; i++) {
          const value = data[i + line * catPerLine];
          if (!value) continue; 
          result[line].push(value);
        }
      }
      this.splitedCategories = result;
      return;
    }
    this.loginService.showLoader.next(true);
    this.jobService.browseCategory().subscribe(
      (res) => {
        this.loginService.showLoader.next(false);
        if (res.response == 3) {
          this.loginService.categoriesList = res;
          this.categoriesList = res.categoriesList;
          let data = [...this.categoriesList];
          const n = 2;
          const result = [[], []]; 

          const catPerLine = Math.ceil(data.length / 2);

          for (let line = 0; line <= n; line++) {
            for (let i = 0; i < catPerLine; i++) {
              const value = data[i + line * catPerLine];
              if (!value) continue; 
              result[line].push(value);
            }
          }
          this.splitedCategories = result;
        } else {
          const txtmsg = res.message;
          const txtmsg2 =  this.translateService.instant(txtmsg);         
          this.openSnackBar(txtmsg2, '');
        }
      },
      (err: HttpErrorResponse) => {
        this.loginService.showLoader.next(false);
        const txtmsg = err.message.trim();
          const txtmsg2 =  this.translateService.instant(txtmsg);         
          this.openSnackBar(txtmsg2, '');
      }
    );
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }
 
  showService(item:any) {   
    if (this.loggedIn == true) {   
      const objString = JSON.stringify(item);     
      localStorage.setItem("myObject", objString); 
       this.loginService.callPostJob.next(true);      
    }
    else {
      this.loginService.callLogin.next(true);
    }
 } 
  
}
