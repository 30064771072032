import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { HeaderComponent } from '../header/header.component';
import { LoginService } from 'src/app/services/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { VerifyOtpComponent } from '../verify-otp/verify-otp.component';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  selectedLanguage: string;
  forgotForm: FormGroup;
  otpForm: FormGroup;
  newPasswordForm: FormGroup;
  loading: boolean = false;
  heading: string = "Forgot Password"
  showForgotScreen: boolean = true;
  showOtpScreen: boolean = false;
  showNewPasswordScreen: boolean;
  userId: string;
  hide = true;
  hide1 = true;
  public otpCountdown: number = 120;
  public otpTimer: any;
  resendButtonDisabled: boolean = false;
  selectCountryObj: any;


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<HeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private loginService: LoginService, private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private dialog: MatDialog
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
    this.loginService.gotOtp.subscribe(val => {
      if (val == true) {
        this.showForgotScreen = false;
        this.showOtpScreen = true;
        this.heading = "Verification"
        this.userId = localStorage.getItem('userID')
      }
    })
  }

  ngOnInit() {
    this.switchLanguage(this.selectedLanguage);
    this.selectCountryObj = JSON.parse(localStorage.getItem('CountryInformation'));

    this.forgotForm = this.fb.group({
      userID: ["", [Validators.required]],
      isReSendOtp: [false]
    })
    this.otpForm = this.fb.group({
      userID: [""],
      otp: ["", [Validators.required, Validators.pattern(/^\+?\d+$/)]]
    })
    this.newPasswordForm = this.fb.group({
      userID: [""],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required]
    },
      { validators: this.passwordConfirming })
  }

  passwordConfirming(c: AbstractControl) { //: { invalid: boolean }

    if (c.get('password').value !== c.get('confirmPassword').value) {
      c.get('confirmPassword').setErrors({ NoPassswordMatch: true });
      //return { invalid: true };
    }
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  // Snackbar Messages
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar"
    });
  }
  closeTab() {
    this.dialogRef.close()
  }
  sendEmail() {
    if (this.forgotForm.valid) {
      this.loading = true;
      let obj = { userID: this.forgotForm.value.userID };
      this.loginService.checkUserAvailability(obj).subscribe(
        (res) => {
          if (res.isActive) {
            this.sendDetails();
          } else {
            const txtmsg = res.message;
            const txtmsg2 = this.translateService.instant(txtmsg);
            this.openSnackBar(txtmsg2, '');
            this.loading = false;
          }
        },
        (err: HttpErrorResponse) => {
          const txtmsg = err.message.trim();
          const txtmsg1 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg1, '');
          if (err.error instanceof Error) {
            console.log("Client Side Error", err.headers);
          } else {
            console.log("Server side Error", err.message);
          }
        }
      );
      // this.loginService.forgotPassword(this.forgotForm.value).subscribe((posRes) => {
      //   console.log("PosRes", posRes);

      //   if (posRes.response === 3) {
      //     this.loading = false
      //     this.showForgotScreen = false;
      //     this.showOtpScreen = true;
      //     this.heading = "Verification"
      //     this.userId = this.forgotForm.value.userID
      //     const txtmsg = posRes.message;
      //     const txtmsg1 = this.translateService.instant(txtmsg);
      //     this.openSnackBar(txtmsg1, '');
      //     this.startOtpCountdown();
      //   } else {
      //     this.loading = false
      //     const txtmsg = posRes.message;
      //     const txtmsg2 = this.translateService.instant(txtmsg);
      //     this.openSnackBar(txtmsg2, '');
      //   }
      // }, (err) => {
      //   this.loading = false
      //   console.log("Err", err);
      // })
    } else {
      const txtmsg = this.translateService.instant("Enter Valid Email");
      this.openSnackBar(txtmsg, '');
    }
  }

  sendDetails() {
    this.sendMoibieOtp();
  }
  sendMoibieOtp() {
    let payload = {
      phoneNumber: this.forgotForm.value.userID,
      dialCode: "+91"
    };
    let dialogRef = this.dialog.open(VerifyOtpComponent, {
      panelClass: "col-md-4",
      hasBackdrop: true,
      disableClose: true,
      data: payload,
    });
    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.loading = false;
        //this.startOtpCountdown();
        this.userId = this.forgotForm.value.userID;
        this.showNewPasswordScreen = true;
        this.showForgotScreen = false;
        this.showOtpScreen = false;
        this.heading = "New Password"
      } else {
        this.loading = false;
      }
    })
    //this.sendMobileNumber(data.userID)
  }
  resendOtp() {
    let id = {
      userID: this.userId,
      isReSendOtp: true
    }
    this.loginService.forgotPassword(id).subscribe((posRes) => {
      if (posRes.response == 3) {
        this.showOtpScreen = true;
        this.heading = "Verification"
        const txtmsg = posRes.message;
        const txtmsg3 = this.translateService.instant(txtmsg);
        this.openSnackBar(txtmsg3, '');
        this.resetOtpCountdown();
        this.startOtpCountdown();

      } else {
        const txtmsg = posRes.message;
        const txtmsg2 = this.translateService.instant(txtmsg);
        this.openSnackBar(txtmsg2, '');
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.log("CSE", err);
      } else {
        console.log("SSE", err);

      }
    })
  }
  sendOtp() {
    this.otpForm.patchValue({
      userID: this.userId
    })
    if (this.otpForm.valid) {
      this.loading = true;
      this.loginService.verify(this.otpForm.value).subscribe((posRes) => {
        if (posRes.response == 3) {
          this.loading = false;
          this.startOtpCountdown();
          const txtmsg = posRes.message;
          const txtmsg12 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg12, '');
          this.showNewPasswordScreen = true;
          this.showOtpScreen = false;
          this.heading = "New Password"

        } else {
          this.loading = false;
          const txtmsg = posRes.message;
          const txtmsg1 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg1, '');
        }
      }, (err: HttpErrorResponse) => {
        const txtmsg = err.message;
        const txtmsg6 = this.translateService.instant(txtmsg);
        this.openSnackBar(txtmsg6, '');
      })
    }
  }

  startOtpCountdown() {
    this.resendButtonDisabled = true;
    this.otpTimer = setInterval(() => {
      this.otpCountdown--;
      if (this.otpCountdown <= 0) {
        clearInterval(this.otpTimer);
        this.resendButtonDisabled = false;
      }
    }, 1000);
  }

  resetOtpCountdown() {
    clearInterval(this.otpTimer);
    this.otpCountdown = 120;
    this.resendButtonDisabled = false;
  }


  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  sendNewPass() {
    this.newPasswordForm.patchValue({
      userID: this.userId
    })
    let payLoad = { ...this.newPasswordForm.value };
    delete payLoad.confirmPassword
    let token = localStorage.getItem("token");
    if (this.newPasswordForm.valid) {
      this.loginService.resetPassword(payLoad, token).subscribe((posRes) => {
        if (posRes.response == 3) {
          const txtmsg = posRes.message;
          const txtmsg1 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg1, '');
          this.dialogRef.close()
        } else {
          const txtmsg = posRes.message;
          const txtmsg32 = this.translateService.instant(txtmsg);
          this.openSnackBar(txtmsg32, '');
        }
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("CSE", err);
        } else {
          console.log("SSE", err);
        }
      })
    }
  }
}
