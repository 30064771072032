import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, } from "@angular/material";
import { LoginService } from "src/app/services/login.service";
import { AddEhireCategoryComponent } from "../add-ehire-category/add-ehire-category.component";
import { HeaderComponent } from "../header/header.component";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { JobsService } from "src/app/services/jobs.service";

@Component({
  selector: "app-digital-hiring",
  templateUrl: "./digital-hiring.component.html",
  styleUrls: ["./digital-hiring.component.css"],
})
export class DigitalHiringComponent implements OnInit {
  selectedLanguage: string;
  index: number = 0;
  checkValue: boolean = false;
  loading: boolean = false;
  isMalaysian: boolean = true;
  mediaName: string = "facebook";
  vehicleName: string = "car";
  hasOwnTools: boolean = true;
  categories: Array<any> = [];
  isOtherMedia: boolean = false;
  isOtherVehicle: boolean = false;
  otherMediaName: string = "";
  otherVehicleName: string = "";
  category: any = [];
  vehicleArray: Array<any> = [
    { name: "Car", isSelected: false, payLoadName: "car" },
    { name: "Motor", isSelected: false, payLoadName: "motor" },
    {
      name: "Public Transport",
      isSelected: false,
      payLoadName: "publicTransport",
    },
    { name: "Other", isSelected: false, payLoadName: "other" },
  ];
  mobileForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<HeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loginService: LoginService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private jobsService: JobsService
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    this.mobileForm = this.fb.group({
      mobileNumber: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
    if (
      this.loginService.categoriesList && this.loginService.categoriesList.length > 0) {
      this.category = this.loginService.categoriesList.categoriesList;
      this.categories = this.loginService.categoriesList.categoriesList
        .map((val) => {
          delete val.categoryId;
          delete val.image;
          return val;
        })
        .map((cat) => {
          cat.isSelected = false;
          cat.Experience = 1;
          return cat;
        });
    }
    else {
      this.browseCategory();
    }

    this.switchLanguage(this.selectedLanguage);
  }
  browseCategory() {

    this.loginService.showLoader.next(true);
    this.jobsService.browseCategory().subscribe(
      (posRes) => {
        if (posRes.response == 3) {
          this.category = posRes.categoriesList;
          this.category = this.loginService.categoriesList.categoriesList;
          this.categories = this.loginService.categoriesList.categoriesList
            .map((val) => {
              delete val.categoryId;
              delete val.image;
              return val;
            })
            .map((cat) => {
              cat.isSelected = false;
              cat.Experience = 1;
              return cat;
            });
          this.loginService.showLoader.next(false);

        } else {
          // alert(posRes.message);
        }
      },
      (err: HttpErrorResponse) => {
        this.loginService.showLoader.next(false);
        this.dialogRef.close();
        const txtmsg1 = this.translateService.instant("Server Failure try after some time..");
        // this.openSnackBar(txtmsg1, '');
        if (err.error instanceof Error) {
          console.warn("CSE", err.message);
        } else {
          console.warn("SSE", err.message);
        }
      }
    );

  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  agreeTerms() {
    if (this.checkValue) {
      this.index += 1;
    }
  }
  setIsMalaysian(val) {
    this.isMalaysian = val;
  }
  setMediaName(name) {
    if (name === "others") {
      this.isOtherMedia = true;
      this.mediaName = name;
    } else {
      this.isOtherMedia = false;
      this.mediaName = name;
    }
  }
  setVehicleName(index) {
    if (index == 3) {
      this.isOtherVehicle = !this.isOtherVehicle;
    }
    this.vehicleArray[index].isSelected = !this.vehicleArray[index].isSelected;
  }
  prev() {
    this.index -= 1;
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }
  numericOnly(event: KeyboardEvent) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.key)) {
      event.preventDefault(); // Prevent input if it's not a number
    }
  }
  next() {
    if (this.index === 6) {
      let selectedCount = this.categories.filter((val) => {
        return val.isSelected;
      });

      if (selectedCount.length) {
        let zeroExpCount = selectedCount.filter((val) => {
          return !val.Experience;
        });
        if (zeroExpCount.length) {
          const txtmsg = this.translateService.instant("Experiance should be minimum 1 year");
          this.openSnackBar(txtmsg, '');
        } else {
          this.index += 1;
        }
      } else {
        const txtmsg1 = this.translateService.instant("Select atleast one Category");
        this.openSnackBar(txtmsg1, '');
      }
    } else if (this.index === 4) {
      let selectedCount = this.vehicleArray.filter((val) => {
        return val.isSelected;
      });
      if (this.isOtherVehicle) {
        if (!this.otherVehicleName) {
          const txtmsg2 = this.translateService.instant("Enter the other vehicle name you have..");
          this.openSnackBar(txtmsg2, '');
          return;
        }
      }
      if (selectedCount.length) {
        this.index += 1;
      } else {
        const txtmsg3 = this.translateService.instant("Select atleast one Transport Option");
        this.openSnackBar(txtmsg3, '');
      }
    } else if (this.index == 3) {
      if (this.isOtherMedia) {
        if (this.otherMediaName) {
          this.index += 1;
        } else {
          const txtmsg4 = this.translateService.instant("Enter Other Social Media Name");
          this.openSnackBar(txtmsg4, '');
        }
      } else {
        this.index += 1;
      }
    } else {
      this.index += 1;
    }
  }
  close() {
    this.dialogRef.close();
  }
  selectItem(index) {
    this.categories[index].isSelected = !this.categories[index].isSelected;
  }
  addNewCat() {
    let dailogRef = this.dialog.open(AddEhireCategoryComponent, {
      panelClass: "col-md-3",
      hasBackdrop: true,
      disableClose: true,
      data: this.category
    });
    dailogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.categories.push(val);
      }
    });
  }
  completeHiring() {
    console.log(this.data);
    let filteredCat = this.categories
      .filter((val) => {
        return val.isSelected;
      })
      .map((cat) => {
        delete cat.isSelected;
        delete cat.subCategorys;
        cat.Experience = "" + cat.Experience;
        return cat;
      });
    let vehicles = this.vehicleArray
      .filter((val) => {
        return val.isSelected;
      })
      .map((vehicle) => {
        if (vehicle.payLoadName == "other") {
          return this.otherVehicleName;
        }
        return vehicle.payLoadName;
      });
    let contact = this.mobileForm.get("mobileNumber").value;
    let payLoad = {
      AreYouMalaysian: this.isMalaysian,
      WheredidyouKnowTalenTasker: this.isOtherMedia
        ? this.otherMediaName
        : this.mediaName,
      WhatTransporationyouhave: vehicles,
      Doyouprovideyourowntools: this.hasOwnTools,
      Whatexperienceyouhave: filteredCat,
      ContactNumber: this.data.userID,
      Name: this.data.firstName,
      Email: this.data.accountData.phoneNumber,
    };
    let token = localStorage.getItem("token");
    this.loading = true;
    console.log(payLoad);
    this.loginService.eHiring(payLoad, token).subscribe(
      (res) => {
        console.log(res);
        this.loading = false;
        const txtmsg = res.message;
        const txtmsg2 = this.translateService.instant(txtmsg);
        this.openSnackBar(txtmsg2, '');
        this.index += 1;
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        const txtmsg = err.message.trim();
        const txtmsg2 = this.translateService.instant(txtmsg);
        this.openSnackBar(txtmsg2, '');
      }
    );
  }
}
