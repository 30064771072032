import { Component, OnInit, Inject } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-account-alert-dialog',
  templateUrl: './account-alert-dialog.component.html',
  styleUrls: ['./account-alert-dialog.component.css']
})
export class AccountAlertDialogComponent implements OnInit {
  message:string;
  selectedLanguage: string;
  constructor(private dialogRef: MatDialogRef<LoginComponent>,    
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loginService:LoginService,
    private translateService: TranslateService,
    private languageService: LanguageService) {
      this.languageService.selectedLanguage$.subscribe(language => {
        this.selectedLanguage = language;      
      });
     }

  ngOnInit() {
    this.message = this.data.message
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  
close(){
  this.dialogRef.close(false)
}
submit(){
  this.dialogRef.close(true)
}
}
