import { Component, OnInit, Inject } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  selectedLanguage: string;
  id: any;
  message: string;
  constructor(private dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private loginService: LoginService, private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }
  ngOnInit() {
    this.id = this.data.userID;
    this.message = this.data.message;
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  close() {
    this.dialogRef.close(false)
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "bg-danger"
    });
  }
  submit() {
    let userId = {
      userID: this.id
    }
    this.loginService.forgotPassword(userId).subscribe((posRes) => {
      if (posRes.response === 3) {
        localStorage.setItem('userID', this.id)
        const txtmsg = posRes.message;
        const txtmsg3 = this.translateService.instant(txtmsg);
        this.openSnackBar(txtmsg3, '');

        this.dialogRef.close(true)
        this.loginService.gotOtp.next(true)
        this.loginService.callForgotPass.next(true)
      } else {
        alert(posRes.message)
      }
    }, (err) => {
      console.log("Err", err);
    })
  }

}
