import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { JobDetailsComponent } from "../job-details/job-details.component";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { JobsService } from "src/app/services/jobs.service";
import { HttpErrorResponse } from "@angular/common/http";
import { LoginService } from "src/app/services/login.service";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
})
export class ReportComponent implements OnInit {
  selectedLanguage: string;
  reportForm: FormGroup;
  loading: boolean = false;
  shortLink: string = "https://www.Talentasker.com/#/browsejobs";
  types: Array<any> = [
    "Spam",
    "Rude or Offensive",
    "Breach of marketplace rules",
    "Other",
  ];
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<JobDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jobService: JobsService,
    private loginService: LoginService,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    this.reportForm = this.fb.group({
      type: [this.translateService.instant("Spam"), Validators.required],
      message: ["", Validators.required],
      userID: [""],
    });
    this.getShortLink();
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  setType(type) {
    const translatedType = this.translateService.instant(type);
    this.reportForm.patchValue({
      type: translatedType,
    });

  }
  getShortLink(): any {
    this.loginService.showLoader.next(true);
    let { postID } = this.data;
    let obj = {
      // dynamicLinkInfo: {
      //   domainUriPrefix: "https://talentaskerin.page.link",
      //   link: `https://www.Talentasker.com/#/browsejobs/job/${postID}`,
      //   androidInfo: {
      //     androidPackageName: "com.Softexer.TalenTasker",
      //   },
      //   iosInfo: {
      //     iosBundleId: "com.SoftexerPrivateLimited.TalenTasker",
      //   },
      //   socialMetaTagInfo: {
      //     socialTitle: "Report",
      //     socialDescription: `Report placed on this task`,
      //     socialImageLink:
      //       "https://www.Talentasker.com/assets/Group20.png",
      //   },
      // },
      "dynamicLinkInfo": {
        "domainUriPrefix": "https://talentaskerin.page.link",
        "link": `https://www.talentasker.com/#/browsejobs/job/${postID}`,
        "androidInfo": {
          "androidPackageName": "com.Softexer.TalenTasker"
        },
        "iosInfo": {
          "iosBundleId": "com.SoftexerPrivateLimited.TalenTasker"
        },
        "socialMetaTagInfo": {
          "socialTitle": "Report",
          "socialDescription": `Report placed on this task`,
          "socialImageLink": "https://www.Talentasker.com/assets/Group20.png"
        }
      },
      "suffix": {
        "option": "UNGUESSABLE"
      }
    };

    this.loginService.getShortLink(obj).subscribe(
      (posRes) => {
        this.loginService.showLoader.next(false);
        this.shortLink = posRes.shortLink;
      },
      (err: HttpErrorResponse) => {
        this.loginService.showLoader.next(false);
      }
    );
  }
  submitReport() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.reportForm.patchValue({
      userID: user.userID,
    });
    let payLoad = { ...this.reportForm.value };
    let token = localStorage.getItem("token");
    payLoad.message = `Post Task Link : ${this.shortLink} \n PostID : ${this.data.postID} \n User Name: ${user.firstName} \n Category Type: ${payLoad.type} \n User Comments: ${payLoad.message}`;
    this.jobService.reportTask(payLoad,token).subscribe(
      (posRes) => {
        this.dialogRef.close(posRes);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("CSE", err.message);
          this.dialogRef.close();
        } else {
          console.log("SSE", err.message);
          this.dialogRef.close();
        }
      }
    );
  }
  closeTab() {
    this.dialogRef.close();
  }
}
