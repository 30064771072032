import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  selectedLanguage: string;
  languageCode: string;
  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }
  ngOnInit() {
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  transform(value: any, ...args: any[]): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
          return 'Just now';
      const intervals = {
          'year': 31536000,
          'month': 2592000,
          'week': 604800,
          'day': 86400,
          'hour': 3600,
          'minute': 60,
          'second': 1
      };
      let counter;
      for (const i in intervals) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0)
              if (counter === 1) {
                  return counter + ' ' + i + ' ago'; // singular (1 day ago)
              } else {
                  return counter + ' ' + i + 's ago'; // plural (2 days ago)
              }
      }
  }
  return value;
  }


  // transform(dateString: string, languageCode: string): string {
  //   const timeDifference = Date.now() - Date.parse(dateString);
  //   let timeAgo: string;
  //   languageCode = this.selectedLanguage
  //   const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  //   const weeks = Math.floor(days / 7);
  //   const years = Math.floor(days / 365);

  //   if (years > 0) {
  //     timeAgo = years + ' year ago';
  //   } else if (weeks > 0) {
  //     timeAgo = weeks + ' week ago';
  //   } else {
  //     timeAgo = days + ' day ago';
  //   }
  //   return timeAgo;
  // }


}
