import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-cleaning',
  templateUrl: './cleaning.component.html',
  styleUrls: ['./cleaning.component.css']
})
export class CleaningComponent implements OnInit {
  selectedLanguage: string;
  constructor(private loginService:LoginService, private languageService: LanguageService,
    private translateService: TranslateService,) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;      
    });
   }
  ngOnInit() {
    this.switchLanguage(this.selectedLanguage );
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  openPostJob(){
    this.loginService.callPostJob.next(true) 
   }
}
