export const countryDataInfo = {
    "india@talentasker.com": {
        "CountryName": "India",
        "countryCode": "IN",
        "currency": "INR",
        "Nationality": "Indian",
        "CurrencySymbol": "₹",
        "baseUrl": "https://india-live-api.talentasker.com",
        "imageURL": "../../../assets/India.png",
    },   
}