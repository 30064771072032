import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-how-to-post-job',
  templateUrl: './how-to-post-job.component.html',
  styleUrls: ['./how-to-post-job.component.css']
})
export class HowToPostJobComponent implements OnInit {
  selectedLanguage: string;
  constructor(private loginService:LoginService,
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) {
    this.languageService.selectedLanguage$.subscribe(language => {
      this.selectedLanguage = language;
    });
  }

  ngOnInit() {
    this.switchLanguage(this.selectedLanguage);
  }

  switchLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }
  openPostJob(){
    this.loginService.callPostJob.next(true) 
   }
}
