import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { LoginService } from 'src/app/services/login.service';
import { SignOutComponent } from '../../sign-out/sign-out.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-adminpage',
  templateUrl: './adminpage.component.html',
  styleUrls: ['./adminpage.component.css']
})
export class AdminpageComponent implements OnInit {
  isdetailsPage: boolean = false;
  isSideNavOpen: boolean = false;
  isMainAdmin: boolean = false;
  constructor(private adminService: AdminService, private router: Router,
    private loginService: LoginService, private dialog: MatDialog,) {

  }
  ngOnInit() {
    let token = sessionStorage.getItem('token');
    if (token == null) {
      console.log("Token", token);
      this.router.navigateByUrl('/home');
    };
    // let cinfo = localStorage.getItem("CountryInfo");
    // console.log(cinfo);
    // if(cinfo){
    //   let selectCountry = JSON.parse(cinfo);
    //   this.adminService.selectCountry = selectCountry;
    //   let obj = JSON.parse(cinfo);
    //   this.adminService.baseUrl = obj.baseUrl;
    // }else{
    //   this.router.navigateByUrl('/login');
    // }
    let adminType = sessionStorage.getItem('isMainAdmin')

    this.isMainAdmin = adminType == "1" ? true : false;
  }
  logOut() {
    sessionStorage.clear();
    localStorage.clear();
    // this.loginService.checkIsAdminLoggedIn.next(false);
    // this.router.navigateByUrl('/home');

   
    // window.location.reload();
    // let p ={
    //   IsAdminLogin : false
    // }
    // localStorage.setItem('IsAdminLogin', JSON.stringify(p));
    // this.router.navigateByUrl('/home');
    this.loginService.setFindurl(true);

    this.loginService.checkIsLoggedIn.next(false);
    this.loginService.checkIsAdminLoggedIn.next(false);
    this.loginService.checkIsAdminLogedout.next(true);
    
  }
  openSideNav() {

  }
}
