import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private selectedLanguageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('en');

  constructor(private http: HttpClient) {
    this.loadLanguage();
  }

  get selectedLanguage$() {
    return this.selectedLanguageSubject.asObservable();
  }

  setSelectedLanguage(language: string) {    
    this.selectedLanguageSubject.next(language);
  }
  private loadLanguage(): void {
    const selctLang = localStorage.getItem('language');
    if (selctLang) {
      this.setSelectedLanguage(selctLang)
    }
    else{
      this.setSelectedLanguage('en');
    }
  }

}
